import axios, { AxiosPromise } from 'axios';

export const getModulos = (): AxiosPromise<any> =>
  axios.get(`/painel-api/api/usuario/me/modulos`);

export const favoritarModulo = (modulo: string): AxiosPromise<any> =>
  axios.put(`/painel-api/api/usuario/me/modulo/favoritar/${modulo}`);

export const desfavoritarModulo = (modulo: string): AxiosPromise<any> =>
  axios.delete(`/painel-api/api/usuario/me/modulo/desfavoritar/${modulo}`);
