import { ReactComponent as OxyAdministrativoIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-administrativo.svg';
import { ReactComponent as OxyGovernoDigitalIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-administrativo.svg';
import { ReactComponent as OxyArrecadacaoIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-arrecadacao.svg';
import { ReactComponent as OxyContabilIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-contabil.svg';
import { ReactComponent as OxyEducacionalIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-educacional.svg';
import { ReactComponent as OxyRecursosHumanosIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-recursos-humanos.svg';
import { ReactComponent as OxySocialIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-social.svg';
import React from 'react';

const ModuleInfo: React.FC<{
  valor: string;
  descricao: string;
  favorito: boolean;
  uri: string;
  clickFavorito: any;
  area: string;
}> = ({ valor, descricao, favorito, uri, clickFavorito, area }) => {
  return (
    <div className="card">
      <i
        id={uri}
        className={(favorito ? 'fas fa-star active' : 'far fa-star').concat(
          ' pull-right iconFav'
        )}
        onClick={() => clickFavorito(valor, favorito)}
        style={{ fontSize: '18px' }}
      ></i>
      <a
        id={uri.concat('acessar')}
        href={uri}
        rel="noopener noreferrer"
        target="_blank"
      >
        {area === 'ADMINISTRATIVO' && <OxyAdministrativoIcon />}
        {area === 'ARRECADACAO' && <OxyArrecadacaoIcon />}
        {area === 'CONTABIL' && <OxyContabilIcon />}
        {area === 'EDUCACIONAL' && <OxyEducacionalIcon />}
        {area === 'RECURSOS_HUMANOS' && <OxyRecursosHumanosIcon />}
        {area === 'SOCIAL' && <OxySocialIcon />}
        {area === 'COMUM' && <OxyAdministrativoIcon />}
        {area === 'GOVERNO_DIGITAL' && <OxyGovernoDigitalIcon />}

        <div className="module-align">
          <span className="module-name">{descricao}</span>
        </div>
      </a>
    </div>
  );
};
export default ModuleInfo;
