import { Button, Card, Col, Icon, Row } from '@elotech/components';
import { Provider, ProviderAssociado } from 'common/type';
import React from 'react';

import logoIsseMaringa from './ecity.png';
import { ReactComponent as Govbr } from './govbrColor.svg';
import { ReactComponent as IcpBrasil } from './icp-brasil-icon.svg';
import { ReactComponent as Microsoft } from './microsoft-icon.svg';

type Props = {
  provider: Provider;
  providerAssociado?: ProviderAssociado;
  onAddProvider?: (provider: Provider) => void;
  onRemoveProvider: (provider: Provider) => void;
};

const GovBrNivelColor = {
  1: '#ad8a56',
  2: '#b4b4b4',
  3: '#c9b037'
};

const LogoIsseMaringa: React.FC = () => (
  <img
    src={logoIsseMaringa}
    alt="Acesso Cidadão"
    title="Acesso Cidadão"
    width="48px"
    height="48px"
    style={{ paddingTop: '3px', paddingBottom: '3px' }}
  />
);

const brandIcons = {
  apple: (
    <Icon
      icon={['fab', 'apple']}
      color={'#D0D2D4'}
      size={'4x'}
      title={'Apple'}
    />
  ),
  facebook: (
    <Icon
      icon={['fab', 'facebook-square']}
      color={'#3b5998'}
      size={'4x'}
      title={'Facebook'}
    />
  ),
  google: (
    <Icon
      icon={['fab', 'google']}
      color={'#de5246'}
      size={'4x'}
      title={'Google'}
    />
  ),
  microsoft: (
    <Microsoft
      width="36.75px"
      height="42px"
      style={{ paddingTop: '3px', paddingBottom: '3px' }}
      title={'Microsoft'}
    />
  ),
  certificate: (
    <IcpBrasil
      width="36.75px"
      height="42px"
      style={{ paddingTop: '3px', paddingBottom: '3px' }}
      title="Certificado Digital"
    />
  ),
  govbr: (
    <Govbr
      width="100px"
      height="42px"
      style={{ paddingTop: '3px', paddingBottom: '3px' }}
      title="Gov.br"
    />
  ),
  'isse-teste': <LogoIsseMaringa />,
  'isse-maringa': <LogoIsseMaringa />
};

const ProviderCard: React.FC<Props> = ({
  provider,
  providerAssociado,
  onAddProvider,
  onRemoveProvider
}) => {
  const isAssociated = providerAssociado !== undefined;

  if (!provider.visible && !isAssociated) {
    return null;
  }
  return (
    <Col md={3} sm={4} xs={6}>
      <Card key={provider.provider}>
        <div style={{ height: '150px' }}>
          <Row className={'center mb-xs'}>
            {brandIcons[provider.provider] ?? (
              <Icon
                icon={['fab', 'key']}
                color={'grey'}
                size={'4x'}
                title={provider.provider}
              />
            )}
          </Row>
          <Row className={'center'}>
            {providerAssociado
              ? providerAssociado.username
              : 'Nenhuma conta vinculada'}
            {providerAssociado && providerAssociado.govBRNivel ? (
              <>
                <span> - </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: GovBrNivelColor[providerAssociado.govBRNivel.id]
                  }}
                >
                  Nível {providerAssociado.govBRNivel.descricao}
                </span>
              </>
            ) : (
              undefined
            )}
          </Row>
          <Row>
            {isAssociated ? (
              <Button
                className={'center mt-xs'}
                onClick={() => onRemoveProvider(provider)}
              >
                Desconectar
              </Button>
            ) : !!onAddProvider ? (
              <Button
                className={'center mt-xs'}
                onClick={() => onAddProvider(provider)}
              >
                Conectar
              </Button>
            ) : null}
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default ProviderCard;
