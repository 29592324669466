import {
  ActionButton,
  ActionsGroup,
  Alert,
  Panel,
  SectionTitle,
  Table,
  useShowNotification
} from '@elotech/components';
import { UserService } from 'common/service';
import { UsuarioSessao } from 'common/type';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
  idUsuario: string;
};

const SessoesAtivasSection: React.FC<Props> = ({ idUsuario }) => {
  const [sessoes, setSessoes] = useState<UsuarioSessao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const showNotification = useShowNotification();

  function getSessoesAtivas() {
    setLoading(true);
    UserService.getCurrentUserSessions()
      .then(response => setSessoes(response.data))
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar as sessões ativas do usuário' },
          error
        );
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getSessoesAtivas();
  }, [idUsuario]);

  const logoutSession = (session: UsuarioSessao) => {
    setLoading(true);
    UserService.logoutCurrentUserSession(session.id).then(
      () => {
        showNotification({
          level: 'success',
          title: 'Sessão encerrada com sucesso'
        });
        getSessoesAtivas();
      },
      error => {
        Alert.error(
          {
            title: 'Erro ao encerrar a sessão'
          },
          error
        );
      }
    );
  };
  return (
    <>
      <SectionTitle>Sessões ativas</SectionTitle>
      <Panel isTable>
        <Table values={sessoes} loading={loading}>
          <Table.Column<UsuarioSessao>
            header={'Aplicações'}
            value={item => item.modulos.join(', ')}
          />
          <Table.Column<UsuarioSessao>
            header={'Data início'}
            value={item => (
              <>
                <FormattedDate value={item.dataInicio} timeZone="UTC" />{' '}
                <FormattedTime value={item.dataInicio} />
              </>
            )}
          />
          <Table.Column<UsuarioSessao>
            header={'Data último acesso'}
            value={item => (
              <>
                <FormattedDate value={item.ultimoAcesso} timeZone="UTC" />{' '}
                <FormattedTime value={item.ultimoAcesso} />
              </>
            )}
          />
          <Table.Column<UsuarioSessao>
            header={'IP origem'}
            value={item => item.ipOrigem}
          />
          <Table.Column<UsuarioSessao>
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  key="action-button"
                  icon={'user-times'}
                  label={'Encerrar sessão'}
                  onClick={() => logoutSession(item)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </>
  );
};

export default SessoesAtivasSection;
